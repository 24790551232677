// Swiper.js
import Swiper from 'swiper';
import { Navigation, Pagination, A11y,  EffectFade, Parallax} from 'swiper/modules';


/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-module-NAME]";

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */
const start = (element) => {
};
export default {
    selector,
    start,
};


const zeroPad = (num, size) => {
    const s = "0000" + num;
    return s.substr(s.length-size);
}

// Carousel with content slides (allow for multiple modules on same page)
const carouselWithContentSlidesSwiper = document.querySelectorAll('.carousel-with-content-slides__swiper');

carouselWithContentSlidesSwiper.forEach((module) => {
    const counter = module.querySelector('.carousel-with-content-slides__swiper-counter');

    const carouselWithContentSlides = new Swiper(module, {
        modules: [Navigation, Pagination, A11y, EffectFade, Parallax],
        loop: false,
        spaceBetween: 30,
        parallax: true,
        pagination: false,
        navigation: {
            nextEl: '.carousel-with-content-slides__swiper-btn-next',
            prevEl: '.carousel-with-content-slides__swiper-btn-prev',
        },
        a11y: {
            enabled: true,
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
        },

        // Counter
        runCallbacksOnInit: true,
        on: {
            slideChange: function(){
                if ( counter ) {

                    counter.innerHTML = zeroPad(this.activeIndex + 1, 2)  + `<span class="carousel-with-content-slides__swiper-counter--total"> - ${zeroPad(carouselWithContentSlides.slides.length, 2)}</span>`;
                }
            },
        }
    });

})
// end

// Carousel with cards (allow for multiple modules on same page)
const carouselWithCardsSwiper = document.querySelectorAll('.carousel-with-cards__swiper');


carouselWithCardsSwiper.forEach((module) => {
    const counter = module.querySelector('.carousel-with-cards__swiper-counter');
    const totalSlides = module.querySelectorAll('.swiper-slide').length; // Count total slides dynamically

    // Dynamically determine slidesPerView based on the total slides
    const breakpoints = totalSlides > 4
        ? {
            1024: {
                slidesPerView: 2.5,
            },
            1441: {
                slidesPerView: 3.5,
            }
        }
        : {
            1024: {
                slidesPerView: 2,
            },
            1441: {
                slidesPerView: 3,
            }
        };

    const carouselWithCards = new Swiper(module, {
        modules: [Navigation, Pagination, A11y],
        slidesPerView: 1,
        // slidesToScroll: 1,
        spaceBetween: 20,
        loop: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        pagination: false,
        navigation: {
            nextEl: '.carousel-with-cards__swiper-btn-next',
            prevEl: '.carousel-with-cards__swiper-btn-prev',
        },
        a11y: {
            enabled: true,
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
        },
        breakpoints: breakpoints, // Use dynamically calculated breakpoints

        // Counter
        runCallbacksOnInit: true,
        on: {
            slideChange: function(){
                if ( counter ) {
                    counter.innerHTML = zeroPad(this.activeIndex + 1, 2)  + `<span class="carousel-with-cards__swiper-counter--total"> - ${zeroPad(carouselWithCards.slides.length, 2)}</span>`;
                }
            },
        }
    });
})
// end


// Sector carousel slides (allow for multiple modules on same page)
const sectorCarouselSwiper = document.querySelectorAll('.sector-carousel__swiper');

// Gets carousel width to place controls with right
const sectorCarouselTextCardWidth = () => {
    const sectorCarouselTextWidth = document.querySelector('.sector-carousel__swiper-slide-text')?.offsetWidth;
    const sectorCarouselTextHeight = document.querySelector('.sector-carousel__swiper-slide-text')?.offsetHeight;
    const sectorCarouselControlWrapper = document.querySelectorAll('.sector-carousel__swiper-control-wrapper');

    const slideWrapperWidth = document.querySelector('.sector-carousel__swiper-slide-wrapper')?.offsetWidth;
    const slideWrapperHeight = document.querySelector('.sector-carousel__swiper-slide-wrapper')?.offsetHeight;

    const setSectorCarouselBeforeElement = () => {
        sectorCarouselSwiper.forEach((module) => {

            const isTextLeft = module.querySelector('.sector-carousel__swiper-slide-text').classList.contains('content--1');

            if(window.innerWidth <= 991){
                module.style.setProperty('--top', slideWrapperHeight - sectorCarouselTextHeight +  'px');
                module.style.setProperty('--bottom', '0px');

            } else {
                module.style.setProperty('--bottom', '0px');
                module.style.setProperty('--top', '0px');
            }


            if (isTextLeft) {
                module.style.setProperty('--right', slideWrapperWidth - sectorCarouselTextWidth + 'px');
                module.style.setProperty('--left', '0px');
            } else {
                module.style.setProperty('--right', '0px');
                module.style.setProperty('--left', slideWrapperWidth - sectorCarouselTextWidth + 'px');
            }
        });
    };


    sectorCarouselControlWrapper.forEach((control) => {
        const isTextLeft = control.previousElementSibling.querySelector('.sector-carousel__swiper-slide-text').classList.contains('content--1');

        if(window.innerWidth <= 991){
            control.style.top = slideWrapperHeight - sectorCarouselTextHeight + 40 +  'px';
        } else {
            control.style.top = 40 + 'px';
        }

        if (isTextLeft) {
            control.style.right = slideWrapperWidth - sectorCarouselTextWidth + 40 + 'px';
            control.style.left = 40 + 'px';
            setSectorCarouselBeforeElement();
        } else {
            control.style.left = slideWrapperWidth - sectorCarouselTextWidth + 40 + 'px';
            control.style.right = 40 + 'px';
            setSectorCarouselBeforeElement();
        }
    })
};

addEventListener("resize", (event) => {
    if(sectorCarouselSwiper.length > 0){
        sectorCarouselTextCardWidth();
    }
});

sectorCarouselSwiper.forEach((module) => {
    const counter = module.querySelector('.sector-carousel__swiper-counter');

    const sectorCarousel = new Swiper(module, {
        modules: [Navigation, Pagination, A11y, EffectFade, Parallax],
        loop: false,
        spaceBetween: 30,
        parallax: true,
        pagination: false,
        navigation: {
            nextEl: '.sector-carousel__swiper-btn-next',
            prevEl: '.sector-carousel__swiper-btn-prev',
        },
        a11y: {
            enabled: true,
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
        },

        // Counter
        runCallbacksOnInit: true,
        on: {
            slideChange: function(){
                if ( counter ) {

                    counter.innerHTML = zeroPad(this.activeIndex + 1, 2)  + `<span class="sector-carousel__swiper-counter--total"> - ${zeroPad(sectorCarousel.slides.length, 2)}</span>`;
                }
            },
            // Runs on init of carousel
            init: () => {
                sectorCarouselTextCardWidth();
            }
        },
    });
})
// end
