/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-module-NAME]";

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */
const start = (element) => {
    //console.log(element);
};
export default {
    selector,
    start,
};
