/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-utility-marquee]";

const setMarqueeWidth = (marquee) => {
  const screenWidth = window.innerWidth;
  const ctaWidth = document?.querySelector('.marquee-cta')?.offsetWidth || 0;

  if (marquee && screenWidth >= 788) {
    marquee.style.width = screenWidth - ctaWidth - 2 + 'px';
  } else if (marquee) {
    marquee.style.width = screenWidth + 'px';
  }
};

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */
const start = (element) => {
  const marqueeContent = element.querySelector("ul.marquee-content");

  if (marqueeContent) {
    // TAKE CHILD COUNT ONCE AND DON'T RECALCULATE
    const originalChildrenCount = marqueeContent.children.length;

    // Update CSS variables only once
    element.style.setProperty("--marquee-elements", originalChildrenCount);

    // Clone elements for marquee effect
    const originalChildren = Array.from(marqueeContent.children);

    const updateMarqueeOnResize = () => {
      // Clear extra children from marqueeContent
      Array.from(marqueeContent.children).forEach((child) => {
        if (!originalChildren.includes(child)) {
          marqueeContent.removeChild(child);
        }
      });
    }

    const updateMarquee = () => {

      const marqueeElementsDisplayed = parseInt(
        getComputedStyle(element).getPropertyValue("--marquee-elements-displayed"),
        10
      );

      for (let i = 0; i < marqueeElementsDisplayed; i++) {
        marqueeContent.appendChild(originalChildren[i % originalChildren.length].cloneNode(true));
      }

      const marquee = element.querySelector(".marquee");
      setMarqueeWidth(marquee); // Initialize marquee width before animation starts
    };

    updateMarquee();

    // ResizeObserver setup to observe the last child of marqueeContent
    const resizeObserver = new ResizeObserver(() => {
      updateMarquee();
    });

    originalChildren.forEach((child) => {
      resizeObserver.observe(child);
    })

    // Don't change structure/variables anymore after this point
    window.addEventListener("resize", () => {
      updateMarqueeOnResize();
      updateMarquee();
    });
  }
};
export default {
  selector,
  start,
};
