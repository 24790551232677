/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-module-site-header]";

const siteHeader = document.querySelector(".site-header");
const primaryMenu = document.querySelector(".site-header__main-menu--wrapper");
const menuToggle = document.querySelector(".site-header__menu-toggle");
const siteHeaderLogo = document.querySelector(".site-header__logo");
const isCaseStudy = document.querySelector(".casestudy-template-default");
const mainHero = document.querySelector(".main-hero-with-cta-strip");
const fiftyFiftyHero = document.querySelector(".fifty-fifty-hero");
const contactForm = document.querySelector(".contact-form-plus-details");


const menuButton = document.querySelector('.openbtn');

const mainEl = document.querySelector('#main');

const iseeprWhiteBlackLogo = "site-header__logo--white-black";
const iseeprWhiteBlueLogo = "site-header__logo--white-blue";
const iseeprBlackBlueLogo = "site-header__logo--black-blue";

const updateLogo = (newLogoClass) => {
    siteHeaderLogo.classList.remove(iseeprBlackBlueLogo);
    siteHeaderLogo.classList.remove(iseeprWhiteBlueLogo);
    siteHeaderLogo.classList.remove(iseeprWhiteBlackLogo);
    siteHeaderLogo.classList.add(newLogoClass);
};

const displayWhiteBlackLogo = () => updateLogo(iseeprWhiteBlackLogo);
const displayWhiteBlueLogo = () => updateLogo(iseeprWhiteBlueLogo);
const displayBlackBlueLogo = () => updateLogo(iseeprBlackBlueLogo);

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */
const start = (element) => {
    // Sets Logo Icon based on what hero is showing
    if(mainHero && !isCaseStudy) {
        menuButton.style.background = 'none';
        document.querySelectorAll('.openbtn span').forEach(span => {
            span.style.backgroundColor = 'white';
        });
        siteHeaderLogo.classList.add("site-header__logo--white-blue");
    } else if (fiftyFiftyHero || containsCookiePolicy || containsPrivacyPolicy) {
        siteHeaderLogo.classList.add("site-header__logo--black-blue");
    }

    // if the menu is active, close it on press of Esc.
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            if ( primaryMenu.classList.contains("site-header__main-menu--active") ) {
                primaryMenu.classList.remove("site-header__main-menu--active");
                menuToggle.classList.remove("site-header__menu-toggle--active");
                siteHeader.classList.remove("site-header--menu-open");
                menuButton.classList.toggle('active');
                if(mainHero) {
                    siteHeaderLogo.classList.remove("site-header__logo--white-black");
                    siteHeaderLogo.classList.add("site-header__logo--white-blue");
                } else if (fiftyFiftyHero) {
                    siteHeaderLogo.classList.remove("site-header__logo--white-black");
                    siteHeaderLogo.classList.add("site-header__logo--black-blue");
                }
            }
        }
    });

    // Sticky header on scroll
    let scrollPos = window.scrollY;

    const headerHeight = siteHeader.offsetHeight;

    let isStickyMenu;
    const addClassOnScroll = () => siteHeader.classList.add("site-header--is-sticky");
    const removeClassOnScroll = () => siteHeader.classList.remove("site-header--is-sticky");

    window.addEventListener('scroll', function() {
        scrollPos = window.scrollY;

        // Sets isStickyMenu on scroll
        isStickyMenu = scrollPos >= headerHeight;

        if ( isStickyMenu ) {
            addClassOnScroll();
            displayWhiteBlueLogo();
        } else {
            removeClassOnScroll();
            if (containsCookiePolicy || containsPrivacyPolicy) {
                displayBlackBlueLogo();
                document.querySelectorAll('.openbtn span').forEach(span => {
                    span.style.backgroundColor = 'black';
                });
            } else if(mainHero) {
                displayWhiteBlueLogo();
            } else if (fiftyFiftyHero && !contactForm) {
                displayBlackBlueLogo();
            } else if (contactForm) {
                displayWhiteBlueLogo();
            }else {
                displayBlackBlueLogo();
            }
        }
    });

    // Retrieve the current URL
    const currentUrl = window.location.href;

    // Check if the URL contains 'cookie-policy' or 'privacy-policy'
    const containsCookiePolicy = currentUrl.includes('cookie-policy');
    const containsPrivacyPolicy = currentUrl.includes('privacy-policy');

    if(fiftyFiftyHero){
        displayBlackBlueLogo();
    }

    if(mainHero) {
        displayWhiteBlueLogo();
    }

    if(contactForm){
        displayWhiteBlueLogo();
    }

    // Optionally, perform actions based on the checks
    if (containsCookiePolicy || containsPrivacyPolicy) {
        displayBlackBlueLogo();
        document.querySelectorAll('.openbtn span').forEach(span => {
            span.style.backgroundColor = 'black';
        });
    }

    const toggleMenu = () => {
        primaryMenu.classList.toggle("site-header__main-menu--active");
        menuToggle.classList.toggle("site-header__menu-toggle--active");
        siteHeader.classList.toggle("site-header--menu-open");
        menuButton.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    }

    // Toggle the menu
    menuToggle.addEventListener("click", () => {

        if (primaryMenu.classList.contains("site-header__main-menu--active")) {
            // Close Menu
            toggleMenu();

            if(isStickyMenu){
                displayWhiteBlueLogo();
            }

            if(mainHero){
                displayWhiteBlueLogo()
            } else if (fiftyFiftyHero && isStickyMenu) {
                displayWhiteBlueLogo();
            } else {
                displayBlackBlueLogo();
            }

            if (window.innerWidth <= 768) {
                document.body.classList.remove('overflow-hidden');
            }
        } else {
            // Open  menu
            toggleMenu();
            displayWhiteBlueLogo();

            if (window.innerWidth <= 768) {
                document.body.classList.add('overflow-hidden');
            }
        }
    });

    mainEl.addEventListener('click', () => {
        if (primaryMenu.classList.contains("site-header__main-menu--active")) {
            // Close Menu
            toggleMenu();

            if(mainHero) {
                if(isStickyMenu) {
                    displayWhiteBlueLogo();
                }
            } else if (fiftyFiftyHero) {
                if(isStickyMenu) {
                    displayWhiteBlueLogo();
                } else {
                    displayBlackBlueLogo();
                }
            } else {
                displayBlackBlueLogo();
            }

            if (window.innerWidth <= 768) {
                document.body.classList.remove('overflow-hidden');
            }
        }
    })
};
export default {
    selector,
    start,
};
