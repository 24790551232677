/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-module-scroll-animation]";

// Select all anchors with the data-module-scroll-animation attribute
const ctaLinks = document.querySelectorAll('a.btn.btn-transparent.stretched-link');

// Loop through each and attach event listeners
ctaLinks.forEach(function(link) {
  link.addEventListener('click', function(e) {
    e.preventDefault();  // Prevent default behavior

    const id = this.href.substring(this.href.indexOf('#'));

    // Scroll to the section with ID
    const targetElement = document.querySelector(id);


    if (targetElement) {
      // Calculate the offset considering the header height (95px) + padding (80px)
      const headerHeight = 175;
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      // Smoothly scroll to the calculated position
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  });
});

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry, index) => {
    if(entry.isIntersecting) {
      setTimeout(()=> {
        entry.target.classList.add('show');
      }, index * 250)
    }
    // else if(entry.boundingClientRect.top > 0){
    //   entry.target.classList.remove('show');
    // }
  });
})

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */
const start = (element) => {
  observer.observe(element);
};
export default {
  selector,
  start,
};
