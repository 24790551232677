/**
 * The selector to target whole module container. This is used when loading each page to contextually apply JS to each module.
 * @type {string}
 */
const selector = "[data-utility-increment-counter]";

/**
 *  Run your code here - element is the module element to scope your functionality on
 * @param {HTMLElement} element
 */

const start = (element) => {
  element.innerText = '0';

  const updateCounter = () => {
    // Get the target value from the 'data-target' attribute of the counter element
    const target = +element.getAttribute('data-module-stat-number');

    // Get the current value of the counter
    const current = +element.innerText;

    // Calculate the increment value
    const increment = target / 100;

    // Check if the current value is less than the target value
    if (current < target) {
      // Increment the counter value and update the display
      element.innerText = `${Math.ceil(current + increment)}`;
      // Call the updateCounter function again after a short delay (1 millisecond)
      setTimeout(updateCounter, 25);
    } else {
      // If the current value is greater than or equal to the target value, set the counter to the target value
      element.innerText = target;
    }
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting){
        updateCounter();
      }
    })
  })

  observer.observe(element);
};
export default {
  selector,
  start,
};

