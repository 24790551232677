import ExampleModule from "../modules/ExampleModule.js";
import SiteHeader from "../modules/SiteHeader.js";
import IncrementCounter from "../modules/IncrementCounter.js";
import Marquee from "../modules/Marquee.js";
import ScrollAnimation from "../modules/ScrollAnimation.js";
import Swiper from "../modules/Swiper.js"

/**
 * List of modules to load
 */
const modules = [
    ExampleModule,
    SiteHeader,
    IncrementCounter,
    Marquee,
    ScrollAnimation,
    Swiper,
];

/**
 * Find module elements on the page and start them
 */
const start = () => {
    modules.forEach((singleModule) => {
        const moduleElements = document.querySelectorAll(singleModule.selector);
        moduleElements.forEach((moduleElement) => {
            singleModule.start(moduleElement);
        });
    });
};

export default {
    start,
};
